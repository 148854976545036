import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

const MatelasVideoBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const AbsoluteButton = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 2;
`

const ButtonTitle = styled.span`
  display: none;
`

const DesktopVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  > div {
    position: relative;
    z-index: 1;
  }

  iframe,
  video {
    width: 250% !important;
    height: 250% !important;
    margin: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px;

    @media (max-width: 3200px) {
      width: 200% !important;
      height: 200% !important;
    }

    @media (max-width: 2100px) {
      width: 100% !important;
      height: 130% !important;
    }

    @media (max-width: 1650px) {
      width: 130% !important;
    }

    @media (max-width: 1200px) {
      width: 200% !important;
    }

    @media (max-width: 720px) {
      width: 250% !important;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileVideoWrapper = styled.div`
  width: 100%;
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;

  > div {
    position: relative;
    z-index: 1;
  }

  iframe,
  video {
    width: 100% !important;
    height: 140% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    border: 0px;

    @media (max-width: 420px) {
      width: 125% !important;
      height: 100% !important;
    }

    @media (max-width: 350px) {
      width: 130% !important;
    }
  }

  @media (max-width: 600px) {
    display: block;
  }
`

const DesktopBannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  position: absolute;
  z-index: ${({ loading }) => (loading === "image" ? "2" : "1")};
`

const MobileBannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  position: absolute;
  z-index: ${({ loading }) => (loading === "image" ? "2" : "1")};
`

const TopSection = styled.div`
  display: flex;
  background: #d7edf6;
  width: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #fff;
  min-height: 650px;

  @media (max-width: 2800px) {
    min-height: 510px;
  }

  @media (max-width: 1600px) {
    min-height: 450px;
  }

  @media (max-width: 600px) {
    min-height: 400px;
  }

  :hover {
    color: #fff;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 1200px;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  padding: 0px 20px;
  z-index: 2;
  top: 50%;
  transform: translate(0%, -50%);
  width: fit-content;

  @media (max-width: 600px) {
    width: 100%;
    top: 50%;
  }
`

const Title = styled.div`
  font-size: 60px;
  line-height: 1.2;
  color: #fff;
  font-family: "Museo";
  font-weight: bold;
  text-align: center;

  span {
    display: block;
    font-weight: bold;
  }

  @media (max-width: 850px) {
    font-size: 48px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileTitle = styled.div`
  font-size: 32px;
  line-height: 1.1;
  color: #fff;
  font-family: "Museo";
  font-weight: bold;
  text-align: center;
  display: none;

  span {
    display: block;
    font-weight: bold;
  }

  @media (max-width: 600px) {
    display: block;
  }

  @media (max-width: 350px) {
    font-size: 28px;
  }
`

const Button = styled.a`
  background: ${props => props.theme.colors.button.black};
  font-family: "Museo";
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  color: ${props => props.theme.colors.white.base};
  font-size: 18px;
  padding: 12px 30px;
  line-height: 1.2;
  width: 100%;
  position: relative;
  border-radius: 25px 0px;
  max-width: 220px;
  margin: 30px 0px 0px;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  bottom: 30px;
  padding: 0px 30px;
  z-index: 2;

  @media (max-width: 1600px) {
    padding: 0px 20px;
  }
`

const MobileButton = styled.a`
  background: ${props => props.theme.colors.button.black};
  border-radius: 6px;
  padding: 15px 20px;
  color: ${props => props.theme.colors.white.base};
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  min-width: 220px;
  text-align: center;
  display: none;
  border-radius: 25px 0px;
  position: relative;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 600px) {
    display: block;
    text-align: center;
  }
`

const MatelasVideoBanner = ({ intl, data }) => {
  const [videoLoading, setVideoloading] = useState(true)
  const [IsMobile, setIsMobile] = useState(false)

  const handleLoading = () => {
    setVideoloading(false)
  }

  const handleDesktopVideoData = event => {
    if (event.playedSeconds >= 5.8 && event.playedSeconds <= 12) {
      showSecondContent()
    } else {
      showFirstContent()
    }
  }

  const handleMobileVideoData = event => {
    if (event.playedSeconds >= 5.8 && event.playedSeconds <= 11.8) {
      showMobileSecondContent()
    } else {
      showMobileFirstContent()
    }
  }

  const showFirstContent = () => {
    document.getElementById("home_video_banner_link1").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_link3").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_text1").innerHTML =
      "Le T-shirt qui soulage le dos"
    document.getElementById("home_video_banner_text2").innerHTML =
      "Le T-shirt qui soulage le dos"
  }

  const showSecondContent = () => {
    document.getElementById("home_video_banner_link1").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_link3").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_text1").innerHTML =
      "Le meilleur matelas pour votre dos"
    document.getElementById("home_video_banner_text2").innerHTML =
      "Le meilleur matelas pour votre dos"
  }

  const showMobileFirstContent = () => {
    document.getElementById("home_video_banner_link2").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_link3").href =
      "/eur/shop/solutions-textiles/"
    document.getElementById("home_video_banner_text2").innerHTML =
      "Le T-shirt qui soulage le dos"
  }

  const showMobileSecondContent = () => {
    document.getElementById("home_video_banner_link2").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_link3").href =
      "/eur/shop/matelas/"
    document.getElementById("home_video_banner_text2").innerHTML =
      "Le meilleur matelas pour votre dos"
  }

  const windowResize = () => {
    var w = window.outerWidth
    if (w <= 600) {
      setIsMobile(true)
    }
    $(window).resize(() => {
      var w = window.outerWidth
      if (w <= 600) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })
  }

  useEffect(() => {
    windowResize()
  })

  return (
    data && (
      <MatelasVideoBannerWrapper>
        <TopSection className="HomeVideoBanner">
          {data.video_section_text.button_text &&
            data.video_section_text.button_url && (
              <AbsoluteButton
                href="/eur/shop/solutions-textiles/"
                id="home_video_banner_link3"
              >
                <ButtonTitle>{data.video_section_text.button_text}</ButtonTitle>
              </AbsoluteButton>
            )}
          {!IsMobile ? (
            <DesktopVideoWrapper>
              <DesktopBannerImage
                className="lazyload"
                // data-src="https://static.percko.com/uploads/2b84db12-d4c3-4d00-ae28-fe8149c37167.jpg"
                // data-src="https://static.percko.com/uploads/097dd01d-0d5b-4018-9b2c-e9fea021ba5f.jpg"
                data-src="https://static.percko.com/uploads/ca13dc9d-5101-4430-950c-5792e9a27ea1.jpeg"
                loading={videoLoading ? "image" : "video"}
              />

              <ReactPlayer
                url="https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_video_top_desktop.mp4"
                playing={true}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                playsinline={true}
                playsInline
                progressInterval={200}
                onProgress={handleDesktopVideoData}
                onPlay={() => {
                  handleLoading()
                }}
              />
            </DesktopVideoWrapper>
          ) : (
            <MobileVideoWrapper>
              <MobileBannerImage
                className="lazyload"
                // data-src="https://static.percko.com/uploads/17c6f4b0-cbf2-44e1-9fa5-b0c22fd8c873.jpg"
                data-src="https://static.percko.com/uploads/ce480624-d477-4fb5-b5b8-9151a8e5ac6c.jpg"
                loading={videoLoading ? "image" : "video"}
              />
              <ReactPlayer
                url="https://www.percko.com/videos/percko.com/eur-fr/home-page/home_page_video_top_mobile.mp4"
                playing={true}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                // vimeoconfig={{ iframeParams: { fullscreen: 0 } }}
                playsinline={true}
                playsInline
                progressInterval={200}
                onProgress={handleMobileVideoData}
                onPlay={() => {
                  handleLoading()
                }}
              />
            </MobileVideoWrapper>
          )}
          <TextWrapper lang={intl.locale}>
            {data.video_section_text.title && (
              <Title id="home_video_banner_text1">
                Le T-shirt qui soulage le dos
              </Title>
            )}
            {data.video_section_text.mobile_title && (
              <MobileTitle id="home_video_banner_text2">
                Le T-shirt qui soulage le dos
              </MobileTitle>
            )}
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <Button
                  href="/eur/shop/solutions-textiles/"
                  id="home_video_banner_link1"
                >
                  {data.video_section_text.button_text}
                </Button>
              )}
          </TextWrapper>
          <BottomWrapper>
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <MobileButton
                  href="/eur/shop/solutions-textiles/"
                  id="home_video_banner_link2"
                >
                  {data.video_section_text.button_text}
                </MobileButton>
              )}
          </BottomWrapper>
        </TopSection>
      </MatelasVideoBannerWrapper>
    )
  )
}

export default injectIntl(MatelasVideoBanner)
